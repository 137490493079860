<template>
  <v-container class="grey lighten-5">
    <v-row no-gutters>
      <div class="home">
        <v-row class="text-center">
          <v-col>
            <h2 class="display-1 font-weight-light mb-3" v-html="heading"></h2>
            <div v-if="walk.partnerFound">
              <v-card max-width="344" elevation="4" class="mx-auto mt-12">
                  <v-img :src="imageSrc()" height="200px"></v-img>
                 <div class="title font-weight-light mt-4 mb-2" >
                   Deze leuke wandeling ga je doen met 
                </div>
                <h2 color="blue" class="mb-4">
                  {{ walk.partner.naam }}
                </h2>
                <v-row>
                  <v-col>
                    <v-btn
                      color="primary"
                      target="_blank"
                      :href="'mailto:' + walk.partner.email +'?subject=Wandeling op ' + walk.datum +'&body=hi! Ik wil met je wandelen! Waar spreken we af?'"   
                      >Mail direct</v-btn
                    ><br/><small>Om een locatie & tijd te bespreken</small>
                  </v-col>
                  <v-col>
                      <v-btn  color="secondary" @click.prevent="goToChat(walk.partner.uid, walk.uid, walk.id)">Chat</v-btn>
                    <br /><small>Direct in deze app!</small>
                    <br /><br />
                     
          
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                   <v-btn color="red" text  @click="cancelWalk(walk.partner)"
                      >Annuleer</v-btn><br />
                    <small>Helaas, je loopje gaat niet door</small>
             
                  </v-col>
                  </v-row>
              </v-card>
            </div>
            <div v-else>
              <h3>
                Er zijn {{ matchedWalkers.length }} wandelaar(s) beschikbaar<br>Kies er één!
              </h3>
              <v-card
                max-width="344"
                elevation="4"
                v-for="(match)  in matchedWalkers"
                :key="match.id"
                class="mx-auto mt-8"
                @click="toggleEl(match)"
              ><v-fab-transition >
                    <v-btn
                      class="mt-4"
                      color="dark orange"
                      dark
                      absolute
                      middle
                      right
                      fab
                      
                    >
                      <v-icon v-if="match.isActive">mdi-exclamation</v-icon>
                      <v-icon v-if="!match.isActive">mdi-help</v-icon>
                    </v-btn>
                  </v-fab-transition>
                <v-img :src="imageSrc()" height="200px"></v-img>
                <v-card-title
                  >
                  <h2 class="font-weight-light">Jij &amp;&nbsp; &nbsp;
                  <span v-if="match.isActive" class="pl-1 "> {{ match.naam }}!</span>
                  
                  <span v-else class="padding">&nbsp;....</span>                  
                  </h2>
                </v-card-title>
                <v-card-text>
                  <div v-if="match.isActive">
                    <p>
                      Jij en

                      {{ match.naam }}
                      kunnen samen op lopen!<br />
                      op {{ match.datum }}!
                    </p>

                    <v-btn color="primary" @click="confirmWalk(match)"
                      >Bevestig</v-btn
                    >
                    of
                    <v-btn
                      color="secondary"
                      outlined
                      :href="'mailto:' + match.email"
                      >Mail eerst ff</v-btn
                    >
                  </div>
                </v-card-text>
              </v-card>
            </div>
            <!-- einde van de container v-else -->
          </v-col>
        </v-row>
      </div>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import { walksRef, counterRef } from "../store/firestore";
import * as moment from "moment/moment";
import router from '../router';
import store from '../store';

//  this.$forceUpdate(); is nog een probleem
//  click op v-card ook.
//  ui van de chat 
//  delete message
//  conversationNEW naar conversation?
//  gelezen of niet en notificatie
export default {
  name: "Walk",

  data: () => ({
    heading: "Wandeling",
    walk: "",
    matchedWalkers: [],
    walkers: [],
    conversationId:'',
    images: [
      "raadhuis-min.jpg",
      "pol-min.jpg",
      "meer-min.jpg",
      "KaapseBossen-min.jpg",
    ],
  }),
  created() {
    if (this.$route.params.id) {
      this.getData(this.$route.params.id);
    } else {
      this.heading = "Wandeling niet gevonden";
    }
  },
  filters: {
    moment: function (date) {
      return moment(date).format("DD-M-YYYY")
    },
    momentTime: function (date) {
      return moment(date).format("HH:mm op DD-M-YYYY")
    }
  },

  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: "user",
      matches: "matches",
    }),
  
  },
  methods: {
    imageSrc() {
      return require("../assets/cards/" + this.randomItem(this.images));
    },
    changeCounter(value, uid) {
      counterRef
        .doc(uid)
        .get()
        .then(function (doc) {
            if(doc.exists){
              if(doc.data().count == 0 && value == -1){return false}
              else{
                counterRef
                  .doc(uid)
                  .set({"count":  doc.data().count+ value})
              }
            }else{
              console.log("oeps")
            }
        })
        .catch(function (error) {
          console.error("Error writing document: ", error);
        });
      // einde ref update
    },
    randomItem(items) {
      return items[Math.floor(Math.random() * items.length)];
    },
    confirmWalk(partnerWalk) {
      // huidige wandeling zit op scope this.walk
      // zet de variable "partnerFound = true"
      // zet de variabe "partner = userID" op beide wanderlingen
      var that = this;
      var userWalk = this.walk;
      var partnerWalkRef = walksRef.doc(partnerWalk.id);
      var userWalkRef = walksRef.doc(userWalk.id);
      partnerWalkRef
        .set(
          {
            partnerFound: true,
            partner: userWalk,
          },
          { merge: true }
        )
        .then(function () {
          that.changeCounter(1, that.user.data.uid);
          userWalkRef
            .set(
              {
                partnerFound: true,
                partner: partnerWalk,
              },
              { merge: true }
            )
            .then(function () {
              that.changeCounter(1, partnerWalk.uid);  
              //console.log("Document successfully written!");
            })
            .catch(function (error) {
              console.error("Error writing document: ", error);
            });
        })
        .catch(function (error) {
          console.error("Error writing document: ", error);
        });
    },
    cancelWalk(partnerWalk) {
      // huidige wandeling zit op scope this.walk
      // zet de variable "partnerFound = false"
      // zet de variabe "partner = leeg" op beide wanderlingen
      var that = this;
      var userWalk = this.walk;
      var partnerWalkRef = walksRef.doc(partnerWalk.id);
      var userWalkRef = walksRef.doc(userWalk.id);
      partnerWalkRef
        .set(
          {
            partnerFound: false,
            partner: {},
          },
          { merge: true }
        )
        .then(function () {
           that.changeCounter(-1, that.user.data.uid);
          userWalkRef
            .set(
              {
                partnerFound: false,
                partner: {},
              },
              { merge: true }
            )
            .then(function () {
              //gelukt
                  that.changeCounter(-1, partnerWalk.uid);  
            })
            .catch(function (error) {
              console.error("Error writing document: ", error);
            });
        })
        .catch(function (error) {
          console.error("Error writing document: ", error);
        });
    },

    toggleEl(match) {
      match.isActive = !match.isActive;
      this.$forceUpdate();
    },
    goToChat(matchUID, walkerUID, walkId){
      this.conversationId = walkerUID+matchUID
      if(matchUID < walkerUID){
        this.conversationId = matchUID+walkerUID;
      }    
       store.dispatch("setLastWalk", walkId);
      router.push(/chat/+this.conversationId)
    },

    getData(id) {
      let that = this;
      var singleWalk = walksRef.doc(id);

      singleWalk.onSnapshot(function (doc) {
        if (doc.exists) {
          let data = doc.data();
          that.walk = data;
          that.walk.id = id;
          var datum = moment(data.datum).format("DD MMMM YYYY");
          that.heading =
            "Wandelen op <span style='white-space: nowrap'>" +
            datum +
            "</span>";
          that.findMatches(data.datum);
        } else {
          //console.log("No such document!");
        }
      });
    },
    filterByValue(array, string) {
      return array.filter((o) =>
        Object.keys(o).some((k) =>
          o[k].toLowerCase().includes(string.toLowerCase())
        )
      );
    },
    removeBookedBYPartner(matchedWalker) {
      return matchedWalker.partnerFound !== true;
    },
    filterByValueToo(array, string) {
      return array.filter((o) => {
        return Object.keys(o).some((k) => {
          if (typeof o[k] === "string")
            return o[k].toLowerCase().includes(string.toLowerCase());
        });
      });
    },
    findMatches(datum) {
      var that = this;
      var test = [];
      test = this.filterByValueToo(this.matches, datum); // ontdubbelen
      function removeDuplicates(originalArray, prop) {
          var newArray = [];
          var lookupObject  = {};

          for(var i in originalArray) {
              lookupObject[originalArray[i][prop]] = originalArray[i];
          }

          for(i in lookupObject) {
              newArray.push(lookupObject[i]);
          }
            return newArray;
      }

      var uniqueArray = removeDuplicates(test, "id");
      that.matchedWalkers = uniqueArray.filter(this.removeBookedBYPartner);
    },
  },
};
</script>